var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Install bs-shadow",staticStyle:{"margin-bottom":"20px"}},[_c('div',{staticClass:"top-wrap"},[_c('div',{staticClass:"dflex mb10"},[_vm._m(0),_c('div',{staticClass:"dflex vcenter"},[_c('div',[_c('h6',{staticClass:"mb0"},[_c('b',[_vm._v(" "+_vm._s(_vm.Install.FirstName + " " + _vm.Install.LastName)+" ")])]),_c('p',{staticClass:"lh1 mb0"},[_c('small',[_vm._v(_vm._s(_vm.Install.Name))])])])])],1),_c('div',{staticClass:"mlauto dflex vcenter",staticStyle:{"font-size":"14px"}},[_c('i',{staticClass:"material-icons mr2 fs20"},[_vm._v("schedule")]),_c('p',{staticClass:"lh1 mb0"},[_vm._v(" "+_vm._s(_vm.moment(_vm.Install.AddedDate).fromNow())+" ")])])]),_c('div',{staticClass:"thumb",staticStyle:{"position":"relative","display":"block"},style:({
      backgroundImage: ("url( " + ('https://spacenet3api.spacesaver.com/images/' +
        _vm.Install.ID +
        '/' +
        _vm.Install.Thumbnail) + ")"),
    })},[(_vm.Install.ETO == 1)?_c('span',{staticClass:"eto-tag"},[_vm._v("ETO")]):_vm._e()]),_c('div',{staticClass:"projcard-tagbox",staticStyle:{"margin-bottom":"10px","margin-top":"22px","display":"flex"}},[_c('span',{staticClass:"projcard-tag"},[_vm._v(_vm._s(_vm.Install.Market))]),_c('p',{staticClass:"mb0 lh1 mlauto"},[_c('small',[_vm._v(_vm._s(_vm.Install.PictureCount)+" Image"+_vm._s(_vm.Install.PictureCount > 1 ? "s" : ""))])])]),_c('h5',{staticClass:"dflex mb5 single-line"},[_c('a',{staticStyle:{"color":"#429da8"},attrs:{"href":/eto-and-inspiring-installs/ + _vm.Install.ID}},[_vm._v(_vm._s(_vm._f("truncateTitle")(_vm.Install.Title)))])]),_c('div',{staticClass:"small-lh cool-desc double-line",domProps:{"innerHTML":_vm._s(_vm.$options.filters.truncate(_vm.Install.Description))}}),_c('div',{staticClass:"Install-bottom dflex mt20",staticStyle:{"align-items":"center"}},[_vm._m(1),_c('router-link',{staticClass:"mb0 lh1 mlauto list-btn",staticStyle:{"font-size":"12px"},attrs:{"to":/eto-and-inspiring-installs/ + _vm.Install.ID}},[(_vm.Install.MessageCount == 1)?_c('span',[_vm._v(_vm._s(_vm.Install.MessageCount)+" Comment")]):(_vm.Install.MessageCount > 1)?_c('span',[_vm._v(_vm._s(_vm.Install.MessageCount)+" Comments")]):_c('span',[_vm._v("View Post")])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('avatar',{staticClass:"mr5",staticStyle:{"width":"40px","height":"40px","font-size":"16px"},attrs:{"color":"#fff","username":_vm.Install.FirstName + ' ' + _vm.Install.LastName,"backgroundColor":_vm.colorGetter[Math.floor(Math.random() * _vm.colorGetter.length)]}})},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Likes',{attrs:{"LikeCount":_vm.Install.LikedUsers && _vm.Install.LikedUsers.length
          ? _vm.Install.LikedUsers.length
          : 0,"isLiked":_vm.Install.iLiked == 0 ? false : true,"LikedUsers":_vm.Install.LikedUsers,"PostID":_vm.Install.ID}})}]

export { render, staticRenderFns }