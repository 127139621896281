<template>
    <div>
           
            <div>
              
                    
                <div class="title-wrapper" style="background: #fff; padding: 20px; margin: 20px 0; border-radius: 4px !important;">
                    <div class="row">
                <div class="col-12 col-sm-12 col-6 col-lg-4 block">
               <div style="display: flex; align-items: center; height: 100%;">
                    <h2 class="mb-0" style="font-size: 28px;"> <a class="plain-link" href="/eto-and-inspiring-installs">ETO & Inspiring Installs</a></h2> 
                </div>
    </div>
                <div class="col-12 col-sm-12 col-6 col-lg-8 block">
  
                        <h5 class="mb-0" style="color: rgb(87, 87, 87); font-size: 20px; display: flex; align-items: center;"> Showcase your most impressive installations, share photos, and explain the unique features that make them stand out.</h5> 
      


       
</div>
</div>
      </div>
        <div
          v-if="loading == true"
          class="col-xs-12 col-sm-12 col-12 col-lg-12"
          style="margin: 100px 0;"
        >
          <div class="flex-box-wrap">
            <div class="flex-internal">
              <atom-spinner
                :animation-duration="1000"
                :size="40"
                :color="'#575757'"
              />
              <p>Loading......</p>
            </div>
          </div>
        </div>
        <div class="row">
              <div
                v-bind:key="coolInstall.ItemID"
                v-for="coolInstall in posts.slice(0,2)"
                class="col-12 col-sm-12 col-6 col-lg-6 block"
              >
               <install-card :Install="coolInstall" v-once />
              </div>
              
            </div>
            
        </div>
    </div>
    
  </template>
  
  <script>
  import axios from "axios";
  import { AtomSpinner } from "epic-spinners";
  import InstallCard from "./InstallCardSmall.vue";

  export default {
    name: 'HomeInstalls',
    components: {
    AtomSpinner,
    InstallCard
},
    data() {
      return {
        server: process.env.VUE_APP_SERVER_URL,
        loading: true,
        posts: [],
      };
    },

    created() {
        this.getPosts();
    },

    methods: {
      getPosts(){

        let axiosConfig = {
            headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + localStorage.getItem("SSCUSER"),
            },
        };

        return axios.get(`${this.server}/api/v1/cool-installs/home/${this.USERID}`, axiosConfig).then(response => {

          if (response.status === 401) {
            window.location = "/logout";          
          } else {

            console.log(response.data);
            this.posts = response.data;
            this.loading = false;

          }
        })
        .catch(error => {
          console.log(error);
        });

        
      },
    },
    computed: {
      USERID(){
        return this.$store.state.user.UserId;
      },
    }
}
  </script>
  
  <style scoped>
.project-section{
    padding-top: 30px;
}
.plain-link{
    color: #00b4b4 !important;
}
  </style>
